var HOST_SERVER = window.location.hostname,
  API_ROUTE = "",
  MQTT_URL = "",
  VLS_URL = "",
  SLS_URL = "";
const protocol = window.location.protocol;
const mqtt_protocol = protocol === "http:" ? "ws:" : "wss:";

let dev = true;
if (typeof process.env.REACT_APP_MODE === "undefined") {
  if (typeof process.env.NODE_ENV !== "undefined") {
    if (process.env.NODE_ENV === "production") {
      dev = false;
    }
  }
} else {
  if (process.env.REACT_APP_MODE !== "dev") {
    dev = false;
  }
}

// dev = false;
if (dev) {
  API_ROUTE = protocol + "//" + HOST_SERVER + ":3053/api";
  MQTT_URL = "ws://" + HOST_SERVER + ":4892";
  VLS_URL = protocol + "//" + HOST_SERVER + ":9606";
  // VLS_URL = 'http://localhost:9606'
  // VLS_URL = 'http://vls.utm.innates.my'
  // Signaling Server
  SLS_URL = "ws://" + HOST_SERVER + ":9090";
} else {
  // HOST_SERVER = 'jagadrone.utmsecurity.innates.my'
  // HOST_SERVER = "drones.dev.utmsecurity.innates.my";
  API_ROUTE = protocol + "//" + HOST_SERVER + "/api";
  MQTT_URL = mqtt_protocol + "//" + HOST_SERVER + "/mqtt";
  // VLS_URL = 'http://' + HOST_SERVER + '/vls'
  // VLS_URL = 'http://vls.utm.innates.my'
  // VLS_URL = 'http://vls.innates.my'
  VLS_URL =
    typeof window.VAR_CONFIG === "undefined"
      ? "https://" + HOST_SERVER + "/vls"
      : window.VAR_CONFIG.VLS_SERVER;
  SLS_URL = "wss://" + HOST_SERVER + "/ws";
}
//console.log(mqtt_protocol, MQTT_URL);

export const SERVER = {
  API: {
    Login: API_ROUTE + "/AppUsers/login",
    // Logout: API_ROUTE + '/AppUsers/logout',
    Logout: API_ROUTE + "/AppUsers/newlogout",
    ChangePassword: API_ROUTE + "/AppUsers/change-password",
    ResetPassword: API_ROUTE + "/AppUsers/reset/password",
    resetPasswordByAdmin: API_ROUTE + "/AppUsers/resetpasswordbyadmin",

    Current: {
      MAIN: API_ROUTE + "/Routes",
    },
    Command: {
      SubmitCommand: API_ROUTE + "/Commands/command",
      Drone: API_ROUTE + "/Commands/drones",
      ChargingStation: API_ROUTE + "/Commands/chargeStation",
    },
    Planner: {
      MAIN: API_ROUTE + "/Planners",
      UpdateLocation: (id) => {
        return API_ROUTE + "/Planners/" + id;
      },
      Remove: (id) => {
        return API_ROUTE + "/Planners/remove/" + id;
      },
      Waypoint: (id) => {
        return API_ROUTE + "/Planners/" + id + "/waypoint";
      },
      RemoveWaypoint: (id, fk) => {
        return API_ROUTE + "/Planners/" + id + "/waypoint/" + fk;
      },
      UpdateWaypoint: (id) => {
        return API_ROUTE + "/Waypoints/" + id;
      },
    },
    Drone: {
      List: API_ROUTE + "/Drones",
      Register: API_ROUTE + "/Drones/register",
      GetVLS: API_ROUTE + "/Drones/getVLS",
    },
    FlightVideo: {
      List: API_ROUTE + "/FlightVideos",
      Stream: API_ROUTE + "/FlightVideos/getstreams",
      Video: API_ROUTE + "/Stores",
      Updates: (id) => {
        return API_ROUTE + "/FlightVideos/" + id;
      },
    },
  },
  MQTT: {
    URL: MQTT_URL,
    User: "drones_dev_dash",
    Pass: "asdh@453bia&2sfeeYq3rs",
  },
  LiveStream: {
    VLS_URL: VLS_URL,
    Stream: VLS_URL + "/api/streams",
  },
  SLS_URL: SLS_URL,
};
