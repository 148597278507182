import { CONSTANTS } from '../IRCore/api/constants';
let initState = {
  drones: [], //
  dronesAppSerialId: {},
  currentVideo: {
    appSerialId: ''
  },
  selectedDrone: {
    set: false,
    droneId: '',
    name: ''
  },
  telemetryData: {
    isPanic: false,
    latPanic: 0,
    lngPanic: 0,
    // "droneId": {}
  },
  defaultTelemetry: {
    data: {
      battery: 0.0,
      voltage: 0.0,
      current: 0.0,
      network: 0,
      flightTime: 0,
      isArmed: false,
      latitude: 1.5603437876997344,
      longitude: 103.63915789947508,
      attitude: 0.0,
      airspeed: 0.0,
      adi: {
        pitch: 0.0,
        roll: 0.0,
        yaw: 0.0
      },
      heading: 0,
      altimeter: 0,
      turnCoordinator: 0,

      isPanic: false,
      latPanic: 0,
      lngPanic: 0,
      updatedAt: Date.now()
    }
  },
  liveData: {},
  liveDrone: {}
}

export function drone(state = initState, action) {
  // console.log(state)
  let liveDrone = state.liveDrone
  switch (action.type) {
    case CONSTANTS.DRONE.LISTS:
      let dronesAppSerialId = {}
      for (let i = 0; i < action.data.length; i++) {
        dronesAppSerialId[action.data[i].appSerialId] = action.data[i]
      }

      return {
        ...state,
        drones: action.data,
        dronesAppSerialId: dronesAppSerialId
      }
    case CONSTANTS.DRONE.SET_CURRENT_VIDEO_FEED:
      return {
        ...state,
        currentVideo: { appSerialId: action.data }
      }
    case CONSTANTS.DRONE.SET_CURRENT_SELECTED:
      return {
        ...state,
        selectedDrone: { ...action.data, set: true }
      }
    case CONSTANTS.DRONE.UNSET_CURRENT_SELECTED:
      return {
        ...state,
        selectedDrone: { set: false, droneId: '', name: '' }
      }
    case CONSTANTS.DRONE.TELEMETRY_DATA:
      let teledata = state.telemetryData
      teledata[action.data.droneId] = action.data

      if (typeof liveDrone[action.data.droneId] === 'undefined') {
        liveDrone[action.data.droneId] = {
          isNew: true,
          droneId: action.data.droneId,
          missionId: action.data.missionId,
          latitude: action.data.latitude,
          longitude: action.data.longitude,
          timesmili: new Date().valueOf()
        }
      } else {
        // liveDrone[action.data.droneId].isNew = false
        // if (liveDrone[action.data.droneId].missionId !== action.data.missionId) {
        //   liveDrone[action.data.droneId].isNew = true
        //   liveDrone[action.data.droneId].missionId = action.data.missionId
        //   console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++')
        // }
        liveDrone[action.data.droneId].missionId = action.data.missionId
        liveDrone[action.data.droneId].latitude = action.data.latitude
        liveDrone[action.data.droneId].longitude = action.data.longitude
        liveDrone[action.data.droneId].timesmili = new Date().valueOf()
      }

      return {
        ...state,
        telemetryData: teledata,
        liveDrone: liveDrone
      }
    case CONSTANTS.DRONE.ADD_LIVE_DATA:
      // liveData = {"abcd3":{lat:"",lng:""}}
      let data = state.liveData
      data[action.data.appSerialId] = {
        lat: action.data.lat,
        lng: action.data.lng,
        dates: new Date().valueOf()
      }

      return {
        ...state,
        liveData: data
      }
    case CONSTANTS.DRONE.REMOVE_LIVE_DATA:
      let liveData = state.liveData
      delete liveData[action.data]

      return {
        ...state,
        liveData: liveData
      }

    case CONSTANTS.DRONE.REMOVE_LIVE_DRONE:
      delete liveDrone[action.data]
      teledata = state.telemetryData
      delete teledata[action.data]

      return {
        ...state,
        liveDrone: liveDrone,
        telemetryData: teledata
      }

    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}
