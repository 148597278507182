import { CONSTANTS } from '../IRCore/api/constants';
let initState = {
  data: [],
  dataList: [],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1
  }
}

export function histories(state = initState, action) {
  // console.log(state)
  let __state = {}
  switch (action.type) {
    case CONSTANTS.HISTORY.COUNT_CHANGE:
      __state = { ...state }
      __state.pagination.itemPerPage = action.data
      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.data.length / action.data)

      __state.dataList = []
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.data) {
          __state.dataList.push(__state.data[i])
        }
      }

      return __state
    case CONSTANTS.HISTORY.PAGE_CHANGE:
      __state = { ...state }
      __state.pagination.currentPage = action.data

      __state.dataList = []
      let _j = 0
      for (let i = ((action.data - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i])
        }
        _j++
      }

      return __state
    case CONSTANTS.HISTORY.FLIGHT_VIDEO_LIST:
      __state = { ...state }
      __state.data = action.data

      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.data.length / __state.pagination.itemPerPage)

      __state.dataList = []
      for (let i = 0; i < __state.data.length; i++) {
        if (i < __state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i])
        }
      }

      return __state
    case CONSTANTS.CLEAR:
      return initState
    default:
      return state
  }
}