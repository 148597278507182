import { combineReducers } from 'redux'
import { dashboard } from './dashboard'
import { planner } from './planner'
import { drone } from './drone'
import { histories } from './histories'
import { charging } from './charging'

const rootReducer = combineReducers({
  dashboard,
  planner,
  drone,
  histories,
  charging
})
export default rootReducer