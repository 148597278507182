import { CONSTANTS } from "../IRCore/api/constants";
let initState = {
  batery: 0,
  current: 0,
  soc: 0,
  timeLeftCharge: "-",
  distanceFan: 0,
  distanceRelay: 0,
  status: "",
  station: [],

  canopy: "",
  id: "",
  voltage: 0,
  docked: "",
  charging: "",
  canopy: "",
};

export function charging(state = initState, action) {
  // console.log(state)
  switch (action.type) {
    case CONSTANTS.CHARGING.BMS_DATA:
      return {
        ...state,
        ...action.data,
      };

    case CONSTANTS.CLEAR:
      return initState;
    default:
      return state;
  }
}
