// View Page
const DASHBOARD = {
    VIEW: 'DASHBOARD_VIEW',
    REQUEST: 'DASHBOARD_REQUEST',
    SUCCESS: 'DASHBOARD_SUCCESS',
    FAILURE: 'DASHBOARD_FAILURE',
    CURRENT_DATA: 'DASHBOARD_CURRENT_DATA',
    SET_DRONE_SELECTED: 'DASHBOARD_SET_DRONE_SELECTED',
}
const PLANNER = {
    COMMAND_REQUEST: 'PLANNER_COMMAND_REQUEST',
    COMMAND_SUCCESS: 'PLANNER_COMMAND_SUCCESS',
    COMMAND_FAILURE: 'PLANNER_COMMAND_FAILURE',
    MARKER_LIST: 'PLANNER_MARKER_LIST',
    UPDATE_MARKER: 'PLANNER_UPDATE_MARKER',
    UPDATE_ALT: 'PLANNER_UPDATE_ALT',
    LISTS: 'PLANNER_LISTS',
    UPDATE_CURRENT_PLAN: 'PLANNER_UPDATE_CURRENT_PLAN',
    WAYPOINT_SELECTED: 'PLANNER_WAYPOINT_SELECTED',
    MAP_CENTER_VIEW: 'PLANNER_MAP_CENTER_VIEW',
    UPDATE_ALTITUDE: 'PLANNER_UPDATE_ALTITUDE',
    UPDATE_LIST_PLAN: 'PLANNER_UPDATE_LIST_PLAN'
}
const CONTROLLER = {
    COMMAND_REQUEST: 'CONTROLLER_COMMAND_REQUEST',
    COMMAND_SUCCESS: 'CONTROLLER_COMMAND_SUCCESS',
    COMMAND_FAILURE: 'CONTROLLER_COMMAND_FAILURE',
    SET_SPEED: 'CONTROLLER_SET_SPEED'
}
const DRONE = {
    LISTS: 'DRONE_LISTS',
    SET_CURRENT_VIDEO_FEED: 'DRONE_SET_CURRENT_VIDEO_FEED',
    SET_CURRENT_SELECTED: 'DRONE_SET_CURRENT_SELECTED',
    UNSET_CURRENT_SELECTED: 'DRONE_UNSET_CURRENT_SELECTED',
    TELEMETRY_DATA: 'DRONE_TELEMETRY_DATA',
    ADD_LIVE_DATA: 'DRONE_ADD_LIVE_DATA',
    REMOVE_LIVE_DATA: 'DRONE_REMOVE_LIVE_DATA',
    START_MONITOR: 'DRONE_START_MONITOR',
    STOP_MONITOR: 'DRONE_STOP_MONITOR',
    REMOVE_LIVE_DRONE: 'DRONE_REMOVE_LIVE_DRONE',
}
const HISTORY = {
    COUNT_CHANGE: 'HISTORY_COUNT_CHANGE',
    PAGE_CHANGE: 'HISTORY_PAGE_CHANGE',
    FLIGHT_VIDEO_LIST: 'HISTORY_FLIGHT_VIDEO_LIST'
}
const CHARGING = {
    BMS_DATA: 'CHARGING_BMS_DATA'
}

// Others
const ROLE = {
    ADMIN: 'admin',
    MANAGER: 'manager',
    SUPERVISOR: 'supervisor',
    NORMALUSER: 'normal',
}

const PATH = {
    LOGIN: '/login'
}

export const CONSTANTS = {
    CLEAR: 'CLEAR',
    TIME_IDLE: 30, // value in minute
    ROLE,
    PATH,

    DASHBOARD,
    PLANNER,
    CONTROLLER,
    DRONE,
    HISTORY,
    CHARGING,
}