import { NFetch, SERVER } from ".";
import Dates from "../func/dates";
const Package = require("../../../package.json");
const APPS_AUTH_USER = "__apps__" + Package.name;
const STORAGE = "session"; // local|session

// Auth path
const USE_AUTH = true;

class Auth {
  static getAuthEnabled() {
    return USE_AUTH;
  }
  static async loginByUsername(username, password) {
    return this.login({ username: username, password: password });
  }
  static async loginByEmail(email, password) {
    return this.login({ email: email, password: password });
  }
  static async login(credentials) {
    try {
      let result = await NFetch.Post(SERVER.API.Login, credentials);
      // console.log(result)
      let AuthData = {
        token: result.token ? result.token : result.id ? result.id : "",
        name: result.name ? result.name : "",
        username: result.username ? result.username : "",
        uid: result.uid ? result.uid : "",
        role: result.role ? result.role : "",
        contact: result.contact ? result.contact : "",
        company: result.company ? result.company : "",
        created: result.created
          ? Dates.getDateTimeWithFormat(result.created, Dates.FORMAT.DATE_TIME5)
          : "",
        picture: result.picture ? result.picture : "",
      };
      this.saveAuthUser(AuthData);
      return Promise.resolve(AuthData);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  static async logout() {
    try {
      await NFetch.Post(SERVER.API.Logout);
      return Promise.resolve("OK");
    } catch (error) {
      return Promise.reject(error);
    }
  }
  static getAuthUser() {
    let user =
      STORAGE === "session"
        ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
        : JSON.parse(localStorage.getItem(APPS_AUTH_USER));

    if (user) {
      return user;
    } else {
      return null;
    }
  }
  static getAuthUserAccessToken() {
    let user =
      STORAGE === "session"
        ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
        : JSON.parse(localStorage.getItem(APPS_AUTH_USER));
    if (user && user.token) {
      return user.token;
    } else {
      return null;
    }
  }
  static saveAuthUser(user) {
    if (STORAGE === "session") {
      sessionStorage.setItem(APPS_AUTH_USER, JSON.stringify(user));
    } else {
      localStorage.setItem(APPS_AUTH_USER, JSON.stringify(user));
    }
  }
  static removeAuthUser() {
    if (STORAGE === "session") {
      sessionStorage.removeItem(APPS_AUTH_USER);
    } else {
      localStorage.removeItem(APPS_AUTH_USER);
    }
  }

  static getAuthUserId() {
    let user =
      STORAGE === "session"
        ? JSON.parse(sessionStorage.getItem(APPS_AUTH_USER))
        : JSON.parse(localStorage.getItem(APPS_AUTH_USER));

    if (user) {
      return user.uid;
    } else {
      return null;
    }
  }
}
export default Auth;
